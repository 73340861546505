import { injectable } from "inversify";
import databaseInstance from "../../index";
import { IGroupService } from "../../../core/services/groups";
import { groupActions } from "../../../store/actions";
import store from "../../../store/configureStore";
import { Group } from "../../../core/models/groups";
import { Post } from "../../../core/models/posts";
import { IGroupUpdateRequestParams, TGroupMenuList } from "interfaces";
import { IInviteMembersFormData } from "containers/groupMembers/InviteMembers/InviteMembers";

@injectable()
export class GroupService implements IGroupService {
  private dbInstance: any = databaseInstance;

  private static instance: GroupService;

  constructor() {
    GroupService.instance = this;
  }

  public static getInstance() {
    if (!GroupService.instance) {
      GroupService.instance = new GroupService();
    }
    return GroupService.instance;
  }

  public createGroup: (
    userId: number,
    name: string,
    description: string,
    status: string,
    // messaging_allowed: boolean,
    slug?: string,
    planId?: any,
    paymentId?: any,
    betaAccessCode?: any,
    customBranding?: any,
    coupon_code?: any,
    referral_code?: any,
  ) => Promise<any> = (
    userId,
    name,
    description,
    status,
    // messaging_allowed,
    slug,
    planId,
    paymentId,
    betaAccessCode,
    customBranding,
    coupon_code,
    referral_code,
  ) => {
      return new Promise<any>((resolve, reject) => {
        const groupDetails: any = {
          name,
          description,
          status,
          // messaging_allowed,
          subdomain: slug,
          plan_id: planId,
          payment_method_id: paymentId,
          beta_access_code: betaAccessCode,
          custom_branding: customBranding,
        }

        if (coupon_code) {
          groupDetails.coupon_code = coupon_code;
        }
        if (referral_code) {
          groupDetails.referral_code = referral_code
        }

        return this.dbInstance
          .post(`/users/${userId}/groups`, groupDetails)
          .then((response: any) => {
            resolve(response);
          })
          .catch((error: any) => {
            reject(error);
            new Error("Something went wrong!")
          });
      });
    };

  public updateGroup: (
    userId: string,
    groupId: string,
    payload: Partial<IGroupUpdateRequestParams>
  ) => Promise<void> = (userId, groupId, payload) => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
        .put(`/users/${userId}/groups/${groupId}`, payload)
        .then((response: any) => {
          resolve(response);
        }).catch((error: any) => {
          reject(error);
          new Error("Something went wrong!")
        });
    });
  };

  public updateGroupSteps: (
    userId: number,
    groupId: number,
    payload: any,
  ) => Promise<void> = (userId, groupId, payload) => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
        .put(`/users/${userId}/groups/${groupId}`, payload)
        .then((response: any) => {
          resolve(response);
        }).catch((error: any) => {
          reject(error);
          new Error("Something went wrong!")
        });
    });
  };


  public deleteGroup: (groupId: number) => Promise<void> = groupId => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance.delete(`groups/${groupId}`).then((response: any) => {
        resolve(response);
      }).catch((error: any) => {
        reject(error);
        new Error("Something went wrong!")
      });
    });
  };

  public verifyCustomDomain: (groupId: number, domain: string) => Promise<void> = (groupId, domain) => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance.post(`groups/${groupId}/custom-domain`, { custom_domain: domain })
        .then((response: any) => {
          resolve(response);
        }).catch((error: any) => {
          reject(error);
          new Error("Something went wrong!")
        });
    });
  };

  public removeCustomDomain: (groupId: number) => Promise<void> = (groupId) => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance.delete(`groups/${groupId}/custom-domain`)
        .then((response: any) => {
          resolve(response);
        }).catch((error: any) => {
          reject(error);
          new Error("Something went wrong!")
        });
    });
  };

  public getGroup(groupSlug: any) {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
        .get(`groups/${groupSlug}?include=channels:30,plans`)
        .then((response: any) => {
          if (response && response.data) {
            resolve(response.data);
          }
        })
        .catch((error: any) => {
          reject(error);
          new Error("Something went wrong!")
        });
    });
  }

  public editGroup: (
    userId: number,
    groupId: number,
    description: string
  ) => Promise<void> = (userId, groupId, description) => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
        .put(`/users/${userId}/groups/${groupId}`)
        .then((response: any) => {
          resolve(response);
        }).catch((error: any) => {
          reject(error);
          new Error("Something went wrong!")
        });
    });
  };

  public getUserGroups: (userId: number) => Promise<void> = userId => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance.get(`/users/${userId}/groups?include=group`).then((response: any) => {
        if (response && response.data) {
          resolve(response);
        }
      }).catch((error: any) => {
        reject(error);
        new Error("Something went wrong!")
      });
    });
  };

  public getAllGroups: (
    searchQuery?: string,
    excludeQueries?: boolean
  ) => Promise<Group[]> = (searchQuery, excludeQueries = false) => {
    return new Promise<Group[]>((resolve, reject) => {
      this.dbInstance
        .get(`/groups?search=${searchQuery || ""}${!excludeQueries ? '&include=channels:30,plans' : ''}`)
        .then((response: any) => {
          if (response && response.data) {
            resolve(response.data);
          }
        }).catch((error: any) => {
          reject(error);
          new Error("Something went wrong!")
        });
    });
  };

  public uploadTextEditorImage: (groupId: number, image: FormData) => Promise<void> = (
    groupId,
    image
  ) => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
        .post(`/groups/${groupId}/text-editor-image-uploads`, image)
        .then((response: any) => {
          resolve(response);
        }).catch((error: any) => {
          reject(error);
          new Error("Something went wrong!")
        });
    });
  };

  public getRecommendedGroups: () => Promise<Group[]> = () => {
    return new Promise<Group[]>((resolve, reject) => {
      this.dbInstance.get("/groups/recommendations").then((response: any) => {
        if (response && response.data) {
          resolve(response.data);
        }
      }).catch((error: any) => {
        reject(error);
        new Error("Something went wrong!")
      });
    });
  };

  public validateCouponCode: (groupId: number, couponCode: string, planId: number) => Promise<any> = (groupId, couponCode, planId) => {
    return new Promise<any>((resolve, reject) => {
      this.dbInstance.post(`/groups/${groupId}/coupons/validate`, { code: couponCode, plan_id: planId })
        .then((response: any) => {
          resolve(response);
        })
        .catch((error: any) => {
          reject(error);
          new Error("Something went wrong!")
        });
    });
  };

  public joinGroup: (
    userId: number,
    groupId: number,
    planId?: number
  ) => Promise<any> = (userId, groupId, planId) => {
    if (!userId) {
    }
    return new Promise<any>((resolve, reject) => {
      this.dbInstance
        .post(`groups/${groupId}/membership-requests`, {
          user_id: userId,
          group_plan_id: planId
        })
        .then((response: any) => {
          if (userId) {
            // @ts-ignore
            store.store.dispatch(groupActions.dbGetUserGroups(userId));
          }
          resolve(response);
        }).catch((error: any) => {
          reject(error);
          new Error("Something went wrong!")
        });
    });
  };

  public membershipRequest: (
    userId: number,
    groupId: number,
    paymentMethodId: any,
    groupPlanId: any,
    couponCode?: any,
    inviteConfigId?: number | null,
    isTosChecked?: boolean,
    payload?: any,
  ) => Promise<any> = (userId, groupId, paymentMethodId, groupPlanId, couponCode, inviteConfigId, isTosChecked, payload = {}) => {
    if (!userId) {
    }
    return new Promise<any>((resolve, reject) => {
      this.dbInstance
        .post(`groups/${groupId}/membership-requests`, {
          user_id: userId,
          payment_method_id: paymentMethodId,
          group_plan_id: groupPlanId,
          coupon_code: couponCode,
          invitation_config_id: inviteConfigId,
          tos_accepted: isTosChecked,
          ...payload,
        })
        .then((response: any) => {
          if (userId) {
            // @ts-ignore
            store.store.dispatch(groupActions.dbGetUserGroups(userId));
          }
          resolve(response);
        })
        .catch((error: any) => {
          reject(error);
          new Error("Something went wrong!")
        });
    });
  };

  public leaveGroup: (userId: number, groupId: number) => Promise<void> = (
    userId,
    groupId
  ) => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
        .delete(`/users/${userId}/groups/${groupId}`)
        .then((response: any) => {
          if (userId) {
            // @ts-ignore
            store.store.dispatch(groupActions.dbGetUserGroups(userId));
          }
          resolve(response);
        }).catch((error: any) => {
          reject(error);
          new Error("Something went wrong!")
        });
    });
  };

  public updateGroupCoverImage: (
    groupId: number,
    cover_image: any
  ) => Promise<void> = (groupId, coverImage) => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
        .post(`/groups/${groupId}/cover-image`, coverImage)
        .then((response: any) => {
          if (response && response.data) {
            resolve(response.data);
          }
        }).catch((error: any) => {
          reject(error);
          new Error("Something went wrong!")
        });
    });
  };

  public updateGroupProfileImage: (
    groupId: number,
    profile_image: any
  ) => Promise<void> = (groupId, profileImage) => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
        .post(`/groups/${groupId}/profile-image`, profileImage)
        .then((response: any) => {
          if (response && response.data) {
            resolve(response);
          }
        }).catch((error: any) => {
          reject(error);
          new Error("Something went wrong!")
        });
    });
  };

  public updateGroupFavicon: (
    groupId: number,
    favicon: any
  ) => Promise<void> = (groupId, favicon_image) => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
        .post(`/groups/${groupId}/favicon-image`, favicon_image)
        .then((response: any) => {
          if (response && response.data) {
            resolve(response);
          }
        }).catch((error: any) => {
          reject(error);
          new Error("Something went wrong!")
        });
    });
  };

  public paginationQuery: (
    paginationQuery: string
  ) => Promise<any> = paginationQuery => {
    return new Promise<any>((resolve, reject) => {
      if (paginationQuery) {
        this.dbInstance.get(paginationQuery).then(
          (response: any) => {
            if (response && response.data) {
              response.data.data = response.data.data.map((post: Post) => {
                return new Post(post);
              });
              resolve(response.data);
            }
          },
          (error: any) => {
            resolve({ data: [] });
          }
        ).catch((error: any) => {
          reject(error);
          new Error("Something went wrong!")
        });
      } else {
        resolve({ data: [] });
      }
    })
  };

  public loadMore: (query: any, limit: any, search: any) => Promise<void> = (
    query,
    limit,
    search
  ) => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
        .get(`${query}&search=${search}&limit=${limit}`)
        .then((response: any) => {
          if (response && response.data) {
            resolve(response.data);
          }
        }).catch((error: any) => {
          reject(error);
          new Error("Something went wrong!")
        });
    });
  };

  public verifySubdomain: (subdomain: any) => Promise<void> = subdomain => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
        .post("/groups/check-availability", {
          subdomain
        })
        .then((response: any) => {
          resolve(response);
        })
        .catch((error: any) => {
          resolve(error);
          new Error("Something went wrong!")
        });
    });
  };

  public verifyGroupName: (name: any) => Promise<void> = name => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
        .post("/groups/check-availability", {
          name
        })
        .then((response: any) => {
          resolve(response);
        })
        .catch((error: any) => {
          resolve(error);
          new Error("Something went wrong!")
        });
    });
  };

  public verifyBetaKey: (
    beta_access_code: any
  ) => Promise<void> = beta_access_code => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
        .post("/groups/check-availability", {
          beta_access_code
        })
        .then((response: any) => {
          resolve(response);
        })
        .catch((error: any) => {
          resolve(error);
          new Error("Something went wrong!")
        });
    });
  };

  public getGroupPosts: (
    groupId: number,
    limit?: any,
    page?: any,
    search?: string
  ) => Promise<void> = (groupId, limit, page, search) => {
    return new Promise<void>((resolve, reject) => {
      let query = `/groups/${groupId}/posts?sort=published_at:desc&include=comments`;
      if (search) {
        query += `&search=${search}`;
      }
      if (limit) {
        query += `&limit=${limit}`;
      }
      if (page) {
        query += `&page=${page}`;
      }
      this.dbInstance.get(query).then((response: any) => {
        if (response && response.data) {
          response.data.data = response.data.data.map((post: Post) => {
            return new Post(post);
          });
          resolve(response.data);
        }
      }).catch((error: any) => {
        reject(error);
        new Error("Something went wrong!")
      });
    });
  };

  public loadMorePosts: (
    groupId: number,
    limit?: number,
    page?: number,
    channelId?: any
  ) => Promise<void> = (groupId, limit, page, channelId) => {
    return new Promise<void>((resolve, reject) => {
      let query = `/groups/${groupId}/channels/${channelId}/posts?sort=published_at:desc&include=comments&page=${page}`;
      if (limit) {
        query += `&limit=${limit}`;
      }
      this.dbInstance.get(query).then((response: any) => {
        if (response && response.data) {
          response.data.data = response.data.data.map((post: Post) => {
            return new Post(post);
          });
          resolve(response.data);
        }
      }).catch((error: any) => {
        reject(error);
        new Error("Something went wrong!")
      });
    });
  };

  public getGroupPost: (groupId: number, postId: number, commentId?: number) => Promise<Post> = (groupId, postId, commentId) => {
    const validcommentId = ((commentId !== null) && (commentId !== undefined))
    return new Promise<Post>((resolve, reject) => {
      this.dbInstance.get(`/groups/${groupId}/posts/${postId}?&include=postable${validcommentId ? `&commentId=${commentId}` : ""}`)
        .then((response: any) => {
          if (response && response.data) {
            store.store.dispatch(
              groupActions.loadedGroup(response.data.group.subdomain)
            );
            resolve(new Post(response.data));
          } else {
            reject(null);
          }
        },
          (e: any) => {
            reject(e);
          }
        ).catch((error: any) => {
          reject(error);
          new Error("Something went wrong!")
        });
    });
  };

  public getGroupPlanBenefits: (groupId: any) => Promise<any> = groupId => {
    return new Promise<any>((resolve, reject) => {
      this.dbInstance
        .get(`/groups/${groupId}/benefits`)
        .then((response: any) => {
          resolve(response && response.data);
        }).catch((error: any) => {
          reject(error);
          new Error("Something went wrong!")
        });
    });
  };

  public getSubscriptionPlanBenefits: (
    groupId: any,
    planId: any
  ) => Promise<any> = (groupId, planId) => {
    return new Promise<any>((resolve, reject) => {
      this.dbInstance
        .get(`/groups/${groupId}/plans/${planId}/benefits`)
        .then((response: any) => {
          resolve(response && response.data);
        }).catch((error: any) => {
          reject(error);
          new Error("Something went wrong!")
        });
    });
  };

  public getSubscriptions: (userId: any) => Promise<any> = userId => {
    return new Promise<any>((resolve, reject) => {
      this.dbInstance
        .get(`/users/${userId}/membership-subscriptions`)
        .then((response: any) => {
          resolve(response && response.data);
        }).catch((error: any) => {
          reject(error);
          new Error("Something went wrong!")
        });
    });
  };

  public getPlanMembers: (
    groupId: any,
    planId: any,
    limit?: number,
    page?: number,
    query?: string
  ) => Promise<any> = (groupId, planId, limit = 25, page = 1, query = "") => {
    return new Promise<any>((resolve, reject) => {
      this.dbInstance
        .get(`/groups/${groupId}/plans/${planId}/members?limit=${limit}&page=${page}&nsearch=${query}`)
        .then((response: any) => {
          resolve(response && response.data);
        }).catch((error: any) => {
          reject(error);
          new Error("Something went wrong!")
        });
    });
  };

  public createGroupPlanBenefit: (
    groupId: any,
    planId: number,
    benefit: any,
    position: number
  ) => Promise<any> = (groupId, planId, benefit, position) => {
    return new Promise<any>((resolve, reject) => {
      this.dbInstance
        .post(`/groups/${groupId}/plans/${planId}/benefits`, {
          benefit: benefit,
          position: position
        })
        .then((response: any) => {
          if (response) {
            resolve(response.data);
          }
        }).catch((error: any) => {
          reject(error);
          new Error("Something went wrong!")
        });
    });
  };

  public createGroupPlanBenefits: (
    groupId: any,
    planId: number,
    benefits: any
  ) => Promise<any> = (groupId, planId, benefits) => {
    return new Promise<any>((resolve, reject) => {
      this.dbInstance
        .post(`/groups/${groupId}/plans/${planId}/bulk-benefits`, {
          benefits: benefits
        })
        .then((response: any) => {
          if (response) {
            resolve(response);
          }
        }).catch((error: any) => {
          reject(error);
          new Error("Something went wrong!")
        });
    });
  };

  public activateFreePlan: (groupId: any) => Promise<any> = groupId => {
    return new Promise<any>((resolve, reject) => {
      this.dbInstance
        .post(`/groups/${groupId}/plans/free`)
        .then((response: any) => {
          if (response) {
            resolve(response.data);
          }
        }).catch((error: any) => {
          reject(error);
          new Error("Something went wrong!")
        });
    });
  };

  public deleteGroupPlanBenefits: (
    groupId: any,
    benefitId: any
  ) => Promise<any> = (groupId, benefitId) => {
    return new Promise<any>((resolve, reject) => {
      this.dbInstance
        .delete(`/groups/${groupId}/benefits/${benefitId}`)
        .then((response: any) => {
          resolve(response && response.data);
        }).catch((error: any) => {
          reject(error);
          new Error("Something went wrong!")
        });
    });
  };

  public updateBenefit: (
    groupId: any,
    benefitId: any,
    benefit: any,
    position: number
  ) => Promise<any> = (groupId, benefitId, benefit, position) => {
    return new Promise<any>((resolve, reject) => {
      this.dbInstance
        .put(`/groups/${groupId}/benefits/${benefitId}`, {
          benefit: benefit,
          position: position
        })
        .then((response: any) => {
          resolve(response && response.data);
        }).catch((error: any) => {
          reject(error);
          new Error("Something went wrong!")
        });
    });
  };

  public createGroupPost: (
    groupId: number,
    channelId: number,
    postObject: any
  ) => Promise<void> = (groupId, channelId, postObject) => {
    return new Promise<void>((resolve, reject) => {
      return this.dbInstance
        .post(`/groups/${groupId}/channels/${channelId}/posts`, postObject)
        .then((response: any) => {
          if (response && response.data) {
            resolve(response.data);
          }
        })
        .catch((error: any) => {
          reject(error);
          new Error("Something went wrong!")
        });
    });
  };

  public getMediaUsage: (
    groupId: number
  ) => Promise<void> = (groupId) => {
    return new Promise<void>((resolve, reject) => {
      return this.dbInstance
        .get(`/groups/${groupId}/media-storage-usage`)
        .then((response: any) => {
          if (response) {
            resolve(response);
          }
        })
        .catch((error: any) => {
          reject(error);
          new Error("Something went wrong!")
        });
    });
  };

  public createSubscriptionPlan: (
    planData: any,
    groupId: number,
    interval: any,
    name: any,
    price: any,
    trialDays: any,
    currency: string,
    segmentIds: Array<number>,
    description?: string,
    intervalCount?: number,
  ) => Promise<void> = (planData, groupId, interval, name, price, trialDays, currency, segmentIds, description, intervalCount) => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
        .post(`/groups/${groupId}/plans`, {
          interval: interval,
          name: name,
          price: price,
          trial_days: trialDays,
          currency: currency,
          description: description,
          interval_count: intervalCount,
          segment_ids: segmentIds,
          ...planData,
        })
        .then((response: any) => {
          if (response && response.data) {
            resolve(response.data);
          }
        })
        .catch((error: any) => {
          reject(error);
          new Error("Something went wrong!")
        });
    });
  };

  public updateSubscriptionPlan: (
    groupId: any,
    planId: any,
    requestPayload: any,
  ) => Promise<any> = (groupId, planId, requestPayload) => {
    return new Promise<any>((resolve, reject) => {
      this.dbInstance
        .put(`/groups/${groupId}/plans/${planId}`,
          requestPayload
        )
        .then((response: any) => {
          resolve(response && response.data);
        })
        .catch((error: any) => {
          reject(error);
          new Error("Something went wrong!")
        });
    });
  };

  public changeSubscriptionPlan: (
    groupId: any,
    planId: any,
    price: any,
    trialDays: any
  ) => Promise<void> = (groupId, planId, price, trialDays) => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
        .put(`/groups/${groupId}/plans/${planId}`, {
          price,
          trial_days: trialDays
        })
        .then((response: any) => {
          resolve(response);
        }).catch((error: any) => {
          reject(error);
          new Error("Something went wrong!")
        });
    });
  };

  public initiateStripeConnect: (
    groupId: number
  ) => Promise<void> = groupId => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
        .post(`/groups/${groupId}/initiate-stripe-connect`)
        .then((response: any) => {
          if (response && response.data) {
            resolve(response.data);
          }
        }).catch((error: any) => {
          reject(error);
          new Error("Something went wrong!")
        });
    });
  };

  public toggleGroupPayment: (groupId: number) => Promise<void> = groupId => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
        .post(`/groups/${groupId}/payment`)
        .then((response: any) => {
          if (response && response.data) {
            resolve(response.data);
          }
        }).catch((error: any) => {
          reject(error);
          new Error("Something went wrong!")
        });
    });
  };

  public disableGroupPayment: (groupId: number) => Promise<void> = groupId => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
        .delete(`/groups/${groupId}/payment`)
        .then((response: any) => {
          if (response && response.data) {
            resolve(response.data);
          }
        }).catch((error: any) => {
          reject(error);
          new Error("Something went wrong!")
        });
    });
  };

  public authorizeStripeConnect: (
    groupId: number,
    code: any,
    state: any
  ) => Promise<void> = (groupId, code, state) => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
        .post(`/groups/${groupId}/authorize-stripe-connect`, {
          code,
          state
        })
        .then((response: any) => {
          if (response && response.data) {
            resolve(response.data);
          }
        }).catch((error: any) => {
          reject(error);
          new Error("Something went wrong!")
        });
    });
  };

  public changePaymentMethod: (
    groupId: any,
    membershipId: any,
    paymentMethodId: any
  ) => Promise<void> = (groupId, membershipId, paymentMethodId) => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
        .put(
          `/groups/${groupId}/memberships/${membershipId}/subscriptions/payment-method`,
          {
            payment_method_id: paymentMethodId
          }
        )
        .then((response: any) => {
          resolve(response);
        })
        .catch((error: any) => {
          reject(error);
          new Error("Something went wrong!")
        });
    });
  };

  public broadcastPost: (postId: any) => Promise<void> = postId => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
        .post(`/posts/${postId}/broadcast`)
        .then((response: any) => {
          resolve(response);
        }).catch((error: any) => {
          reject(error);
          new Error("Something went wrong!")
        });
    });
  };

  public deleteGroupPost: (postId: any) => Promise<void> = postId => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance.delete(`/posts/${postId}`).then((response: any) => {
        if (response && response.data) {
          resolve(response.data);
        }
      }).catch((error: any) => {
        reject(error);
        new Error("Something went wrong!")
      });
    });
  };

  public editGroupPost: (postId: any, body: any) => Promise<void> = (postId, body) => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance.put(`/posts/${postId}`, body).then((response: any) => {
        if (response && response.data) {
          resolve(response.data);
        }
      }).catch((error: any) => {
        reject(error);
        new Error("Something went wrong!")
      });
    });
  };

  public cancelSubscription: (
    groupId: any,
    membershipId: any,
    payload?: any
  ) => Promise<void> = (groupId, membershipId, payload) => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
        .delete(`/groups/${groupId}/memberships/${membershipId}/subscription`, payload &&
        {
          data: { 'immediately': true }
        }
        )
        .then((response: any) => {
          resolve(response);
        }).catch((error: any) => {
          reject(error);
          new Error("Something went wrong!")
        });
    });
  };

  public resumeSubscription: (
    groupId: any,
    membershipId: any
  ) => Promise<void> = (groupId, membershipId) => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
        .put(`/groups/${groupId}/memberships/${membershipId}/subscription/resume`)
        .then((response: any) => {
          resolve(response);
        }).catch((error: any) => {
          reject(error);
          new Error("Something went wrong!")
        });
    });
  };

  public getMembershipInvoices: (
    userId: any,
    membershipId: any
  ) => Promise<void> = (userId, membershipId) => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
        .get(`/users/${userId}/memberships/${membershipId}/invoices`)
        .then((response: any) => {
          if (response) {
            resolve(response);
          }
        }).catch((error: any) => {
          reject(error);
          new Error("Something went wrong!")
        });
    });
  };

  public changeMembershipPlan: (
    groupId: any,
    membershipId: any,
    planId: any,
    paymentMethod?: any,
    couponCode?: any,
    isTosChecked?: boolean,
    inviteConfigId?: number | null,
  ) => Promise<void> = (groupId, membershipId, planId, paymentMethod, couponCode, isTosChecked, inviteConfigId) => {
    return new Promise<void>((resolve, reject) => {
      return this.dbInstance
        .put(`/groups/${groupId}/memberships/${membershipId}/subscription`, {
          plan_id: planId,
          payment_method_id: paymentMethod,
          coupon_code: couponCode,
          tos_accepted: isTosChecked,
          invitation_config_id: inviteConfigId,
        })
        .then((response: any) => {
          if (response) {
            return resolve(response);
          }
        })
        .catch((error: any) => {
          reject(error);
          new Error("Something went wrong!")
        });
    });
  };

  public getGroupMembers: (
    groupId: number,
    query?: string,
  ) => Promise<void> = (groupId, query = "",) => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
        .get(`/groups/${groupId}/memberships?search=${encodeURIComponent(query)}`)
        .then((response: any) => {
          if (response && response.data) {
            const groupMembers: any = response.data;
            resolve(groupMembers);
          }
        }).catch((error: any) => {
          reject(error);
          new Error("Something went wrong!")
        });
    });
  };

  // Bad option. pressed for time.
  public getGroupMembersInMention: (
    groupId: number,
    query?: string,
    limit?: number,
    // canChat?: boolean,
    filter?: string,
    page?: number,
    sort?: boolean
  ) => Promise<void> = (groupId, query = "", limit = 50, filter: string = "", page, sort) => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
        .get(
          `/groups/${groupId}/memberships?nsearch=${encodeURIComponent(
            query
          )}&limit=${limit}&sort=${sort}&page=${page}${filter.length > 0 ? `&${filter}` : ""}`
        )
        .then((response: any) => {
          if (response && response.data) {
            const groupMembers: any = response.data;

            groupMembers.data = groupMembers.data.map((member: any) => {
              let memberType = "";
              let memberStatus = "";

              if (member.approved) {
                memberType = "Member";
                memberStatus = "Active";
              }

              if (member.admin || member.owner) {
                memberType = "Admin";
              }

              if (member.blocked) {
                memberStatus = "Blocked";
              }

              if (!member.user.job_title) {
                member.user.job_title = "N/A";
              }

              if (!member.user.location) {
                member.user.location = "N/A";
              }

              member.user = {
                ...member.user,
                type: memberType,
                status: memberStatus
              };
              return member;
            });

            resolve(groupMembers);
          }
        }).catch((error: any) => {
          reject(error);
          new Error("Something went wrong!")
        });
    });
  };

  public getGroupMembershipRequests: (
    groupId: any
  ) => Promise<void> = groupId => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
        .get(`/groups/${groupId}/membership-requests`)
        .then((response: any) => {
          if (response && response.data) {
            resolve(response);
          }
        }).catch((error: any) => {
          reject(error);
          new Error("Something went wrong!")
        });
    });
  };

  public getAdmin: (groupId: any) => Promise<void> = groupId => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance.get(`/groups/${groupId}/admin`).then((response: any) => {
        if (response && response.data) {
          resolve(response);
        }
      }).catch((error: any) => {
        reject(error);
        new Error("Something went wrong!")
      });
    });
  };

  public postAnalytics: (
    groupId: any,
    startDate: any,
    endDate: any
  ) => Promise<void> = (groupId, startDate, endDate) => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
        .get(
          `/groups/${groupId}/analytics/total-posts?start_time=${startDate}&end_time=${endDate}`
        )
        .then((response: any) => {
          resolve(response);
        }).catch((error: any) => {
          reject(error);
          new Error("Something went wrong!")
        });
    });
  };

  public subscriptionsAnalytics: (
    groupId: any,
    startDate: any,
    endDate: any
  ) => Promise<void> = (groupId, startDate, endDate) => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
        .get(
          `/groups/${groupId}/analytics/membership-subscriptions-revenue?start_time=${startDate}&end_time=${endDate}`
        )
        .then((response: any) => {
          resolve(response);
        }).catch((error: any) => {
          reject(error);
          new Error("Something went wrong!")
        });
    });
  };

  public commentsAnalytics: (
    groupId: any,
    startDate: any,
    endDate: any
  ) => Promise<void> = (groupId, startDate, endDate) => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
        .get(
          `/groups/${groupId}/analytics/total-comments?start_time=${startDate}&end_time=${endDate}`
        )
        .then((response: any) => {
          resolve(response);
        }).catch((error: any) => {
          reject(error);
          new Error("Something went wrong!")
        });
    });
  };

  public visitsAnalytics: (
    groupId: any,
    startDate: any,
    endDate: any
  ) => Promise<void> = (groupId, startDate, endDate) => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
        .get(
          `/groups/${groupId}/analytics/total-visits?start_time=${startDate}&end_time=${endDate}`
        )
        .then((response: any) => {
          resolve(response);
        }).catch((error: any) => {
          reject(error);
          new Error("Something went wrong!")
        });
    });
  };

  public membershipAnalytics: (
    groupId: any,
    startDate: any,
    endDate: any
  ) => Promise<void> = (groupId, startDate, endDate) => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
        .get(
          `/groups/${groupId}/analytics/total-memberships?start_time=${startDate}&end_time=${endDate}`
        )
        .then((response: any) => {
          resolve(response);
        }).catch((error: any) => {
          reject(error);
          new Error("Something went wrong!")
        });
    });
  };

  public membersDMs: (
    groupId: any,
    startDate: any,
    endDate: any
  ) => Promise<void> = (groupId, startDate, endDate) => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
        .get(
          `/groups/${groupId}/analytics/total-direct-messages?start_time=${startDate}&end_time=${endDate}`
        )
        .then((response: any) => {
          resolve(response);
        }).catch((error: any) => {
          reject(error);
          new Error("Something went wrong!")
        });
    });
  };

  public deviceVisits: (
    groupId: any,
    startDate: any,
    endDate: any,
  ) => Promise<void> = (groupId, startDate, endDate) => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
        .get(
          `/groups/${groupId}/analytics/total-devices?start_time=${startDate}&end_time=${endDate}`
        )
        .then((response: any) => {
          resolve(response);
        }).catch((error: any) => {
          reject(error);
          new Error("Something went wrong!")
        });
    });
  };

  public fetchLibraryContent: (
    groupId: any, page?: any
  ) => Promise<void> = (groupId, page) => {

    let url = `/groups/${groupId}/analytics/library-content-access`;
    if (page) {
      url += `/?page=${page}`;
    }
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
        .get(url)
        .then((response: any) => {
          resolve(response);
        }).catch((error: any) => {
          reject(error);
          new Error("Something went wrong!")
        });
    });
  };

  public fetchCoursesAnalytics: (
    groupId: any,
    page?: number
  ) => Promise<void> = (groupId, page) => {
    let url = `/groups/${groupId}/analytics/total-course-enrolments`;
    if (page) {
      url += `/?page=${page}`;
    }
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
        .get(url)
        .then((response: any) => {
          resolve(response);
        }).catch((error: any) => {
          reject(error);
          new Error("Something went wrong!")
        });
    });
  };


  public membershipRequests: (
    groupId: any,
    startDate: any,
    endDate: any,
  ) => Promise<void> = (groupId, startDate, endDate) => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
        .get(
          `/groups/${groupId}/analytics/new-membership-requests?start_time=${startDate}&end_time=${endDate}`
        )
        .then((response: any) => {
          resolve(response);
        }).catch((error: any) => {
          reject(error);
          new Error("Something went wrong!")
        });
    });
  };

  public fetchNewMembers: (
    groupId: any,
    startDate: any,
    endDate: any,
  ) => Promise<void> = (groupId, startDate, endDate) => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
        .get(
          `/groups/${groupId}/analytics/new-memberships?start_time=${startDate}&end_time=${endDate}`
        )
        .then((response: any) => {
          resolve(response);
        }).catch((error: any) => {
          reject(error);
          new Error("Something went wrong!")
        });
    });
  };

  public memberCommentsAndPosts: (
    groupId: any,
    startDate: any,
    endDate: any,
    page?: number,
    sortParam?: any,
  ) => Promise<void> = (groupId, startDate, endDate, page, sortParam) => {
    let url = `/groups/${groupId}/analytics/memberships?start_time=${startDate}&end_time=${endDate}`;
    if (page) {
      url += `&page=${page}`;
    }
    if (sortParam !== undefined) {
      if (sortParam === 'comment_count') {
        url += `&sort=comment_count:desc`;
      }
      if (sortParam === 'post_count') {
        url += `&sort=post_count:desc`;
      }
      if (sortParam === 'visit_count') {
        url += `&sort=visit_count:desc`;
      }
    }

    return new Promise<void>((resolve, reject) => {
      this.dbInstance
        .get(url)
        .then((response: any) => {
          resolve(response);
        })
        .catch((error: any) => {
          reject(error);
          new Error("Something went wrong!")
        });
    });
  };



  public membershipSubscriptionTable: (
    groupId: any
  ) => Promise<void> = groupId => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
        .get(`/groups/${groupId}/analytics/membership-subscriptions-table`)
        .then((response: any) => {
          resolve(response && response);
        }).catch((error: any) => {
          reject(error);
          new Error("Something went wrong!")
        });
    });
  };

  public exportMembershipTable: (groupId: any) => Promise<void> = groupId => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
        .post(
          `/groups/${groupId}/analytics/membership-subscriptions-table/export`
        )
        .then((response: any) => {
          resolve(response && response);
        }).catch((error: any) => {
          reject(error);
          new Error("Something went wrong!")
        });
    });
  };

  public archiveSubscriptionPlan: (
    groupId: any,
    planId: any,
    price: any
  ) => Promise<void> = (groupId, planId, price) => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
        .put(`/groups/${groupId}/plans/${planId}/archive`, {
          price
        })
        .then((response: any) => {
          resolve(response && response);
        }).catch((error: any) => {
          reject(error);
          new Error("Something went wrong!")
        });
    });
  };

  public activateSubscriptionPlan: (
    groupId: any,
    planId: any
  ) => Promise<void> = (groupId, planId) => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
        .put(`/groups/${groupId}/plans/${planId}/activate`)
        .then((response: any) => {
          resolve(response && response);
        }).catch((error: any) => {
          reject(error);
          new Error("Something went wrong!")
        });
    });
  };

  public deleteSubscriptionPlan: (
    groupId: any,
    planId: any
  ) => Promise<void> = (groupId, planId) => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
        .delete(`/groups/${groupId}/plans/${planId}`)
        .then((response: any) => {
          resolve(response && response);
        }).catch((error: any) => {
          reject(error);
          new Error("Something went wrong!")
        });
    });
  };

  public approveGroupMembership: (
    requestId: number
  ) => Promise<void> = requestId => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
        .post(`memberships`, { group_membership_request_id: requestId })
        .then((response: any) => {
          if (response && response.data) {
            resolve(response.data);
          }
        }).catch((error: any) => {
          reject(error);
          new Error("Something went wrong!")
        });
    });
  };

  public declineGroupMembership: (
    requestId: number
  ) => Promise<void> = requestId => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
        .delete(`/membership-requests/${requestId}`)
        .then((response: any) => {
          if (response && response.data) {
            resolve(response.data);
          }
        }).catch((error: any) => {
          reject(error);
          new Error("Something went wrong!")
        });
    });
  };

  public removeGroupMember: (
    groupId: number,
    membershipId: number
  ) => Promise<void> = (groupId, membershipId) => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
        .delete(`/groups/${groupId}/memberships/${membershipId}`)
        .then((response: any) => {
          resolve(response);
        }).catch((error: any) => {
          reject(error);
          new Error("Something went wrong!")
        });
    });
  };

  public changeMembershipBlockState: (
    groupId: number,
    membershipId: number,
    blockState: boolean
  ) => Promise<void> = (groupId, membershipId, blockState) => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
        .put(`/groups/${groupId}/memberships/${membershipId}`, {
          block: blockState
        })
        .then((response: any) => {
          resolve(response);
        }).catch((error: any) => {
          reject(error);
          new Error("Something went wrong!")
        });
    });
  };

  public getPostComments: (postId: number, limit?: number) => Promise<void> = (
    postId,
    limit
  ) => {
    let query = `/posts/${postId}/comments?sort=created_at:asc`;
    if (limit) {
      query += `&limit=${limit}`;
    }
    return new Promise<void>((resolve, reject) => {
      this.dbInstance.get(query).then((response: any) => {
        if (response && response.data) {
          resolve(response.data);
        }
      }).catch((error: any) => {
        reject(error);
        new Error("Something went wrong!")
      });
    });
  };

  public createPostComment: (
    postId: number,
    commentData: any
  ) => Promise<void> = (postId, commentData) => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
        .post(`/posts/${postId}/comments`, commentData)
        .then((response: any) => {
          resolve(response);
        }).catch((error: any) => {
          reject(error);
          new Error("Something went wrong!")
        });
    });
  };

  public editPostComment: (
    commentId: number,
    comment: string,
    mentions: any
  ) => Promise<void> = (commentId, comment, mentions) => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
        .put(`/comments/${commentId}`, { comment, mentions })
        .then((response: any) => {
          resolve(response);
        }).catch((error: any) => {
          reject(error);
          new Error("Something went wrong!")
        });
    });
  };

  public deletePostComment: (commentId: number) => Promise<any> = commentId => {
    return new Promise<any>((resolve, reject) => {
      this.dbInstance.delete(`/comments/${commentId}`).then((response: any) => {
        resolve(response);
      }).catch((error: any) => {
        reject(error);
        new Error("Something went wrong!")
      });
    });
  };

  public reactToPost: (postId: number) => Promise<void> = postId => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
        .post(`/posts/${postId}/reactions`, { type: "like" })
        .then((response: any) => {
          resolve(response);
        }).catch((error: any) => {
          reject(error);
          new Error("Something went wrong!")
        });
    });
  };

  public unreactToPost: (postId: number) => Promise<void> = postId => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
        .delete(`/posts/${postId}/reactions`, { data: { type: "like" } })
        .then((response: any) => {
          resolve(response);
        }).catch((error: any) => {
          reject(error);
          new Error("Something went wrong!")
        });
    });
  };

  public changeGroupInviteLink: (
    groupId: number
  ) => Promise<void> = groupId => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
        .put(`/groups/${groupId}/invite-link/`)
        .then((response: any) => {
          resolve(response);
        }).catch((error: any) => {
          reject(error);
          new Error("Something went wrong!")
        });
    });
  };

  public authenticateGroupInviteLink: (
    slug: string,
    code: string
  ) => Promise<void> = (slug, code) => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
        .get(`/groups/${slug}/invite-code/${code}`)
        .then(
          (response: any) => {
            if (response) {
              resolve(response);
            }
          },
          (e: any) => {
          }
        )
        .catch((error: any) => {
          reject(error);
          new Error("Something went wrong!")
        });
    });
  };

  public individualInvites: (groupId: number, emails: any) => Promise<void> = (
    groupId,
    emails
  ) => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
        .post(`/groups/${groupId}/memberships/invites`, {
          emails
        })
        .then((response: any) => {
          resolve(response);
        }).catch((error: any) => {
          reject(error);
          new Error("Something went wrong!")
        });
    });
  };

  public fileInvite: (groupId: number, file: any) => Promise<void> = (
    groupId,
    file
  ) => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
        .post(`/groups/${groupId}/memberships/invite/csv`, file)
        .then((response: any) => {
          resolve(response);
        }).catch((error: any) => {
          reject(error);
          new Error("Something went wrong!")
        });
    });
  };

  public exportMembers: (
    groupId: number,
    groupMembershipIds: any
  ) => Promise<void> = (groupId, groupMembershipIds) => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
        .post(`/groups/${groupId}/memberships/export`, {
          group_membership_ids: groupMembershipIds
        })
        .then((response: any) => {
          resolve(response);
        }).catch((error: any) => {
          reject(error);
          new Error("Something went wrong!")
        });
    });
  };


  public getGroupPlans: (
    groupId: number,
    query?: string,
    filter?: any
  ) => Promise<void> = (groupId, query = "", filter = "") => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
        .get(`/groups/${groupId}/plans?search=${query}&${filter}`)
        .then((response: any) => {
          resolve(response);
        }).catch((error: any) => {
          reject(error);
          new Error("Something went wrong!")
        });
    });
  };

  public getUserProratedTrialDays: (
    groupId: number,
    planId?: string
  ) => Promise<void> = (groupId, planId) => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
        .get(`/groups/${groupId}/available-trial-days?group_plan_id=${planId}`)
        .then((response: any) => {
          resolve(response);
        }).catch((error: any) => {
          reject(error);
          new Error("Something went wrong!")
        });
    });
  };

  public getGroupPlan: (
    groupId: number,
    planId: string
  ) => Promise<void> = (groupId, planId) => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
        .get(`/groups/${groupId}/plans/${planId}`)
        .then((response: any) => {
          resolve(response);
        }).catch((error: any) => {
          reject(error);
          new Error("Something went wrong!")
        });
    });
  };

  // Start third party integration
  public getAllApplications: (
    groupId: any,
    page?: number,
    limit?: number,
    searchQuery?: string
  ) => Promise<any> = (groupId, page, limit, searchQuery = '') => {
    return new Promise<any>((resolve, reject) => {
      this.dbInstance
        .get(`/groups/${groupId}/third-party-applications?page=${page}&limit=${limit}&q=${searchQuery}`)
        .then((response: any) => {
          resolve(response && response.data);
        }).catch((error: any) => {
          reject(error);
          new Error("Something went wrong!")
        });
    });
  };

  public getAuthorizedApplications: (
    groupId: any,
    page?: number,
    limit?: number,
    searchQuery?: string
  ) => Promise<any> = (groupId, page, limit, searchQuery = "") => {
    return new Promise<any>((resolve, reject) => {
      this.dbInstance
        .get(`/groups/${groupId}/connected-third-party-applications?page=${page}&limit=${limit}&q=${searchQuery}`)
        .then((response: any) => {
          resolve(response && response.data);
        }).catch((error: any) => {
          reject(error);
          new Error("Something went wrong!")
        });
    });
  };

  public revokeAuthorizedApplications: (groupId: number, thirdPartyId: any)
    => Promise<any> = (groupId, thirdPartyId) => {
      return new Promise<any>((resolve, reject) => {
        this.dbInstance
          .post(`/groups/${groupId}/third-party/${thirdPartyId}/revoke`)
          .then((response: any) => {
            resolve(response && response.data);
          }).catch((error: any) => {
            reject(error);
            new Error("Something went wrong!")
          });
      });
    };

  public subscribeToIntegration: (
    groupId: number,
    appId: number
  ) => Promise<void> = (
    groupId,
    appId
  ) => {
      return new Promise<void>((resolve, reject) => {
        this.dbInstance
          .post(`/groups/${groupId}/app-integration-subscription`, {
            app_id: appId
          })
          .then((response: any) => {
            resolve(response);
          }).catch((error: any) => {
            reject(error);
            new Error("Something went wrong!")
          });
      });
    };

  public unsubscribeFromIntegration: (
    groupId: number,
    appId: number
  ) => Promise<void> = (
    groupId,
    appId
  ) => {
      return new Promise<void>((resolve, reject) => {
        this.dbInstance
          .delete(`/groups/${groupId}/app-integration-subscription`, {
            data: { app_id: appId }
          })
          .then((response: any) => {
            resolve(response);
          }).catch((error: any) => {
            reject(error);
            new Error("Something went wrong!")
          });
      });
    };

  public getAppAuthorizationUrl: (
    groupId: number,
    appId: number
  ) => Promise<void> = (
    groupId,
    appId
  ) => {
      return new Promise<void>((resolve, reject) => {
        this.dbInstance
          .post(`/groups/${groupId}/third-party/${appId}/authorization`)
          .then((response: any) => {
            resolve(response.data);
          }).catch((error: any) => {
            reject(error);
            new Error("Something went wrong!")
          });
      });
    };

  public intergrationAuthorization: (
    groupId: number,
    appId: number,
    payload: any
  ) => Promise<void> = (
    groupId,
    appId,
    payload
  ) => {
      return new Promise<void>((resolve, reject) => {
        this.dbInstance
          .post(`/groups/${groupId}/third-party/${appId}/authorization`, payload)
          .then((response: any) => {
            resolve(response.data);
          }).catch((error: any) => {
            reject(error);
            new Error("Something went wrong!")
          });
      });
    };

  public authorizeAppToken: (
    groupId: string,
    appId: string,
    payload: { code: string }
  ) => Promise<void> = (
    groupId,
    appId,
    payload
  ) => {
      return new Promise<void>((resolve, reject) => {
        this.dbInstance
          .post(`/groups/${groupId}/third-party/${appId}/token`, payload)
          .then((response: any) => {
            resolve(response.data);
          }).catch((error: any) => {
            reject(error);
            new Error("Something went wrong!")
          });
      });
    };
  // End third party integration

  public toggleMemberChatState: (
    groupId: number,
    memberId: number,
    can_chat: ConstrainBoolean
  ) => Promise<void> = (
    groupId,
    memberId,
    can_chat
  ) => {
      return new Promise<void>((resolve, reject) => {
        this.dbInstance
          .put(`/groups/${groupId}/memberships/${memberId}/chat`, {
            can_chat: can_chat
          })
          .then((response: any) => {
            resolve(response);
          }).catch((error: any) => {
            reject(error);
            new Error("Something went wrong!")
          });
      });
    };

  public getTimezones: () => Promise<void> = () => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance.get(`/timezones`).then((response: any) => {
        resolve(response);
      }).catch((error: any) => {
        reject(error);
        new Error("Something went wrong!")
      });
    });
  };

  // Invite page and community landing page
  public createInvitePageConfig: (
    groupId: number,
    payload: any
  ) => Promise<void> = (
    groupId,
    payload
  ) => {
      return new Promise<void>((resolve, reject) => {
        this.dbInstance
          .post(`/groups/${groupId}/invite-page-config`, payload)
          .then((response: any) => {
            resolve(response);
          })
          .catch((error: any) => {
            reject(error);
            new Error("Something went wrong!")
          });
      });
    };

  public getInvitePageConfig: (
    groupId: number
  ) => Promise<void> = (
    groupId
  ) => {
      return new Promise<void>((resolve, reject) => {
        this.dbInstance
          .get(`/groups/${groupId}/invite-page-config`)
          .then((response: any) => {
            resolve(response);
          }).catch((error: any) => {
            reject(error);
            new Error("Something went wrong!")
          });
      });
    };

  public updateInvitePageConfig: (
    groupId: number,
    invitePageId: number,
    payload: any
  ) => Promise<void> = (
    groupId,
    invitePageId,
    payload
  ) => {
      return new Promise<void>((resolve, reject) => {
        this.dbInstance
          .put(`/groups/${groupId}/invite-page-config/${invitePageId}`, payload)
          .then((response: any) => {
            resolve(response);
          })
          .catch((error: any) => {
            reject(error);
            new Error("Something went wrong!")
          });
      });
    };

  public saveInvitePageDetails: (
    groupId: number,
    payload: any
  ) => Promise<void> = (
    groupId,
    payload
  ) => {
      return new Promise<void>((resolve, reject) => {
        this.dbInstance
          .post(`/groups/${groupId}/invite-page-config-detail`, payload)
          .then((response: any) => {
            resolve(response);
          })
          .catch((error: any) => {
            reject(error);
            new Error("Something went wrong!")
          });
      });
    };

  public getInvitePageDetails: (
    groupId: number
  ) => Promise<void> = (
    groupId
  ) => {
      return new Promise<void>((resolve, reject) => {
        this.dbInstance
          .get(`/groups/${groupId}/invite-page-config-detail`)
          .then((response: any) => {
            resolve(response);
          }).catch((error: any) => {
            reject(error);
            new Error("Something went wrong!")
          });
      });
    };

  public uploadInvitePageCoverImage: (
    groupId: number,
    invitePageDetailId: number,
    image: any
  ) => Promise<void> = (
    groupId,
    invitePageDetailId,
    image
  ) => {
      return new Promise<void>((resolve, reject) => {
        this.dbInstance
          .post(`/groups/${groupId}/invite-page-config-detail/${invitePageDetailId}/cover-image`, image)
          .then((response: any) => {
            resolve(response);
          }).catch((error: any) => {
            reject(error);
            new Error("Something went wrong!")
          });
      });
    };

  public getInvitePageFaqs: (
    groupId: number,
    invitePageDetailId: number
  ) => Promise<void> = (
    groupId,
    invitePageDetailId
  ) => {
      return new Promise<void>((resolve, reject) => {
        this.dbInstance
          .get(`/groups/${groupId}/invite-page-config-detail/${invitePageDetailId}/faqs`)
          .then((response: any) => {
            resolve(response);
          }).catch((error: any) => {
            reject(error);
            new Error("Something went wrong!")
          });
      });
    };

  public saveFaq: (
    groupId: number,
    invitePageDetailId: number,
    faqs: any
  ) => Promise<void> = (
    groupId,
    invitePageDetailId,
    faqs
  ) => {
      return new Promise<void>((resolve, reject) => {
        this.dbInstance
          .post(`/groups/${groupId}/invite-page-config-detail/${invitePageDetailId}/faqs`, faqs)
          .then((response: any) => {
            resolve(response);
          })
          .catch((error: any) => {
            reject(error);
            new Error("Something went wrong!")
          });
      });
    };

  public updateFaq: (
    groupId: number,
    invitePageDetailId: number,
    faqId: number,
    payload: any
  ) => Promise<void> = (
    groupId,
    invitePageDetailId,
    faqId,
    payload
  ) => {
      return new Promise<void>((resolve, reject) => {
        this.dbInstance
          .put(`/groups/${groupId}/invite-page-config-detail/${invitePageDetailId}/faqs/${faqId}`, payload)
          .then((response: any) => {
            resolve(response);
          })
          .catch((error: any) => {
            reject(error);
            new Error("Something went wrong!")
          });
      });
    };

  public updateFaqPositions: (
    groupId: number,
    invitePageDetailId: number,
    payload: any
  ) => Promise<void> = (
    groupId,
    invitePageDetailId,
    payload
  ) => {
      return new Promise<void>((resolve, reject) => {
        this.dbInstance
          .put(`/groups/${groupId}/invite-page-config-detail/${invitePageDetailId}/faqs/positions`, payload)
          .then((response: any) => {
            resolve(response);
          })
          .catch((error: any) => {
            reject(error);
            new Error("Something went wrong!")
          });
      });
    };

  public deleteFaq: (
    groupId: number,
    invitePageDetailId: number,
    faqId: number
  ) => Promise<any> = (groupId, invitePageDetailId, faqId) => {
    return new Promise<any>((resolve, reject) => {
      this.dbInstance
        .delete(`/groups/${groupId}/invite-page-config-detail/${invitePageDetailId}/faqs/${faqId}`)
        .then((response: any) => {
          resolve(response && response.data);
        })
        .catch((error: any) => {
          reject(error);
          new Error("Something went wrong!")
        });
    });
  };
  // End invite page and community landing page

  public getSinglePlan: (
    planId: string
  ) => Promise<any> = planId => {
    return new Promise<any>((resolve, reject) => {
      this.dbInstance
        .get(`/subscription-plans/${planId}`)
        .then((response: any) => resolve(response && response.data))
        .catch((error: any) => {
          reject(error);
          new Error("Something went wrong!")
        });
    })
  }

  public generateJWTSSOKey: (
    groupId: number
  ) => Promise<any> = (groupId) => {
    return new Promise<any>((resolve, reject) => {
      this.dbInstance
        .post(`/groups/${groupId}/sso/key`)
        .then((response: any) => resolve(response))
        .catch((error: any) => {
          reject(error);
          new Error("Something went wrong!")
        });
    });
  }

  public enableSSO: (
    groupId: number,
    data: any,
  ) => Promise<any> = (groupId, data) => {
    return new Promise<any>((resolve, reject) => {
      this.dbInstance
        .post(`/groups/${groupId}/sso/enable`, data)
        .then((response: any) => resolve(response))
        .catch((error: any) => {
          reject(error);
          new Error("Something went wrong!")
        });
    });
  }

  public disableSSO: (
    groupId: number
  ) => Promise<any> = (groupId) => {
    return new Promise<any>((resolve, reject) => {
      this.dbInstance
        .delete(`/groups/${groupId}/sso/disable`)
        .then((response: any) => resolve(response))
        .catch((error: any) => {
          reject(error);
          new Error("Something went wrong!")
        });
    })
  }

  public getOAuthProviders: (

  ) => Promise<any> = () => {
    return new Promise<any>((resolve, reject) => {
      this.dbInstance
        .get(`/oauth-sso-providers`)
        .then((response: any) => resolve(response))
        .catch((error: any) => {
          reject(error);
          new Error("Something went wrong!")
        });
    });
  }

  public saveOAuthSSO: (
    groupId: number,
    oauthDetails: any,
  ) => Promise<any> = (groupId, oauthDetails) => {
    return new Promise<any>((resolve, reject) => {
      this.dbInstance
        .post(`/groups/${groupId}/oauth-provider/${oauthDetails.oauth_provider_id}/oauth-sso`, oauthDetails)
        .then((response: any) => resolve(response))
        .catch((error: any) => {
          reject(error);
          new Error("Something went wrong!")
        });
    })
  }

  public nbeaLogin: (
    userCredentials: any
  ) => Promise<any> = (userCredentials) => {
    return new Promise<any>((resolve, reject) => {
      this.dbInstance
        .post(`/groups/nbea/login`, userCredentials)
        .then((response: any) => resolve(response))
        .catch((error: any) => {
          reject(error)
          new Error("Something went wrong!")
        });
    })
  }

  public getGroupOAuthSSO: (
    groupId: number
  ) => Promise<any> = (groupId) => {
    return new Promise<any>((resolve, reject) => {
      this.dbInstance
        .get(`/groups/${groupId}/oauth-sso`)
        .then((response: any) => resolve(response))
        .catch((error: any) => {
          reject(error);
          new Error("Something went wrong!")
        });
    })
  }

  public initiateGroupOAuthSSO: (
    groupId: number
  ) => Promise<any> = (groupId) => {
    return new Promise<any>((resolve, reject) => {
      this.dbInstance
        .post(`/groups/${groupId}/initiate-oauth-sso`)
        .then((response: any) => resolve(response))
        .catch((error: any) => {
          reject(error);
          new Error("Something went wrong!")
        });
    })
  }

  public getOAuthAccessToken: (
    groupId: number,
    params: any,
  ) => Promise<any> = (groupId, params) => {
    return new Promise<any>((resolve, reject) => {
      this.dbInstance
        .post(`/groups/${groupId}/oauth-sso/access-token`, params)
        .then((response: any) => resolve(response))
        .catch((error: any) => {
          reject(error);
          new Error("Something went wrong!")
        });
    })
  }

  public toggleGroupAppTrademark: (
    groupId: number,
    payload: any,
  ) => Promise<any> = (groupId, payload) => {
    return new Promise<any>((resolve, reject) => {
      this.dbInstance
        .put(`/groups/${groupId}/toggle-trademark`, payload)
        .then((response: any) => resolve(response))
        .catch((error: any) => {
          reject(error);
          new Error("Something went wrong!")
        });
    })
  }

  public setGroupOnboardingAnswers: (
    groupId: number,
    payload: any,
  ) => Promise<any> = (groupId, payload) => {
    return new Promise<any>((resolve, reject) => {
      this.dbInstance
        .post(`/groups/${groupId}/onboarding-answers`, payload)
        .then((response: any) => resolve(response))
        .catch((error: any) => {
          reject(error);
          new Error("Something went wrong!")
        });
    })
  }

  public deleteGroupPostImageAttachment: (postId: number, attID: number)
    => Promise<void> = (postId, attID) => {
      return new Promise<void>((resolve, reject) => {
        this.dbInstance.delete(`/posts/${postId}/files/${attID}`).then((response: any) => {
          if (response && response.data) {
            resolve(response.data);
          }
        }).catch((error: any) => {
          reject(error);
          new Error("Something went wrong!")
        });
      });
    };

  public deleteCommentFileAttachment: (commentId: number, attID: number)
    => Promise<void> = (commentId, attID) => {
      return new Promise<void>((resolve, reject) => {
        this.dbInstance.delete(`/comments/${commentId}/files/${attID}`)
          .then((response: any) => resolve(response.data)
          ).catch((error: any) => {
            reject(error);
            new Error("Something went wrong!")
          });
      });
    };

  // Group invitations
  public sendGroupInvitation: (
    groupId: number,
    payload: IInviteMembersFormData,
  ) => Promise<any> = (groupId, payload) => {
    return new Promise<any>((resolve, reject) => {
      this.dbInstance
        .post(`/groups/${groupId}/invitation-configs`, payload)
        .then((response: any) => resolve(response))
        .catch((error: any) => {
          reject(error);
          new Error("Something went wrong!")
        });
    })
  }

  public getInvitedMembers: (
    groupId: number,
    query?: string,
    limit?: number,
    filter?: string
  ) => Promise<void> = (groupId, query = "", limit = 50, filter: string = "") => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
        .get(
          `/groups/${groupId}/invited-users?search=${encodeURIComponent(
            query
          )}&limit=${limit}${filter.length > 0 ? `&${filter}` : ""}`
        )
        .then((response: any) => {
          if (response && response.data) {
            resolve(response.data);
          }
        }).catch((error: any) => {
          reject(error);
          new Error("Something went wrong!")
        });
    });
  };

  public getMemberInvitations: (
    groupId: number,
    query?: string,
    limit?: number,
    filter?: string
  ) => Promise<void> = (groupId, query = "", limit = 50, filter: string = "") => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
        .get(
          `/groups/${groupId}/invitation-configs?search=${encodeURIComponent(
            query
          )}&limit=${limit}${filter.length > 0 ? `&${filter}` : ""}`
        )
        .then((response: any) => {
          if (response && response.data) {
            resolve(response.data);
          }
        }).catch((error: any) => {
          reject(error);
          new Error("Something went wrong!")
        });
    });
  };

  public deleteInvitedMembers: (groupId: number, payload: any) => Promise<void> = (groupId, payload) => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance.delete(`groups/${groupId}/invited-users`, payload)
        .then((response: any) => {
          resolve(response);
        }).catch((error: any) => {
          reject(error);
          new Error("Something went wrong!")
        });
    });
  };

  public deleteInvitationConfig: (groupId: number, inviteConfigId: any) => Promise<void> = (groupId, inviteConfigId) => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance.delete(`groups/${groupId}/invitation-configs/${inviteConfigId}`)
        .then((response: any) => {
          resolve(response);
        }).catch((error: any) => {
          reject(error);
          new Error("Something went wrong!")
        });
    });
  };

  public resendMemberInvites: (groupId: number, payload: any) => Promise<void> = (groupId, payload) => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance.post(`groups/${groupId}/invited-users/resend`, payload)
        .then((response: any) => {
          resolve(response);
        }).catch((error: any) => {
          reject(error);
          new Error("Something went wrong!")
        });
    });
  };

  public getInviteConfigById: (
    groupId: number,
    inviteId: string
  ) => Promise<void> = (groupId, inviteId) => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
        .get(
          `/groups/${groupId}/invitation-configs/${inviteId}`
        )
        .then((response: any) => {
          if (response && response.data) {
            resolve(response.data);
          }
        }).catch((error: any) => {
          reject(error);
          new Error("Something went wrong!")
        });
    });
  };

  public updateGroupMenuItems: (groupId: string, payload: Partial<TGroupMenuList>) => Promise<void> = (
    groupId, payload) => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
        .put(`/groups/${groupId}/menu`, payload)
        .then((response: any) => {
          resolve(response);
        }).catch((error: any) => {
          reject(error);
          new Error("Something went wrong!")
        });
    });
  };

  public getCommunityAPIToken: (groupId: number,)
    => Promise<any> = (groupId) => {
      return new Promise<any>((resolve, reject) => {
        this.dbInstance
          .get(`/groups/${groupId}/group-tokens`)
          .then((response: any) => {
            resolve(response && response);
          }).catch((error: any) => {
            reject(error);
            new Error("Something went wrong!")
          });
      });
    };

  public deleteCommunityAPIToken: (groupId: number, tokenId: number) => Promise<void> = (groupId, tokenId) => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance.delete(`groups/${groupId}/group-tokens/${tokenId}`).then((response: any) => {
        resolve(response);
      }).catch((error: any) => {
        reject(error);
        new Error("Something went wrong!")
      });
    });
  };

  public createCommunityAPIToken: (groupId: number, TokenName: any) => Promise<void> = (groupId, TokenName) => {
    const payload = {
      name: TokenName
    }
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
        .post(`groups/${groupId}/group-tokens/`, payload)
        .then((response: any) => {
          resolve(response);
        }).catch((error: any) => {
          reject(error);
          new Error("Something went wrong!")
        });
    });
  };

  public getBroadcastLimit: (
    groupId: number
  ) => Promise<void> = (groupId) => {
    return new Promise<void>((resolve, reject) => {
      return this.dbInstance
        .get(`/groups/${groupId}/limit-usage`)
        .then((response: any) => {
          if (response) {
            resolve(response);
          }
        })
        .catch((error: any) => {
          reject(error);
          new Error("Something went wrong!")
        });
    });
  };

  public createSendingEmailID: (groupId: number, payload: any) => Promise<void> = (groupId, payload) => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
        .post(`groups/${groupId}/email-identities/`, payload)
        .then((response: any) => {
          resolve(response);
        })
        .catch((error: any) => {
          reject(error);
          new Error("Something went wrong!")
        });
    });
  };

  public getSendingEmailID: (groupId: number) => Promise<any> = (groupId) => {
    return new Promise<any>((resolve, reject) => {
      this.dbInstance
        .get(`/groups/${groupId}/email-identities`)
        .then((response: any) => {
          resolve(response && response);
        }).catch((error: any) => {
          reject(error);
          new Error("Something went wrong!")
        });
    });
  };

  public deleteEmailID: (groupId: number, emailID: number) => Promise<any> = (groupId, emailID) => {
    return new Promise<any>((resolve, reject) => {
      this.dbInstance
        .delete(`/groups/${groupId}/email-identities/${emailID}`)
        .then((response: any) => {
          resolve(response && response);
        }).catch((error: any) => {
          reject(error);
          new Error("Something went wrong!")
        });
    });
  };

  public checkDNS: (groupId: number, emailID: number) => Promise<any> = (groupId, emailID) => {
    return new Promise<any>((resolve, reject) => {
      this.dbInstance
        .get(`/groups/${groupId}/email-identities/${emailID}/check-dns`)
        .then((response: any) => {
          resolve(response && response);
        }).catch((error: any) => {
          reject(error);
          new Error("Something went wrong!")
        });
    });
  };

  public updateEmailID: (groupId: number, emailID: number, payload: any) => Promise<any> = (groupId, emailID, payload) => {
    return new Promise<any>((resolve, reject) => {
      this.dbInstance
        .put(`/groups/${groupId}/email-identities/${emailID}`, payload)
        .then((response: any) => {
          resolve(response && response);
        }).catch((error: any) => {
          reject(error);
          new Error("Something went wrong!")
        });
    });
  };

  public getUserMembershipDetails: (
    groupId: number,
    userId: any
  ) => Promise<void> = (groupId, userId) => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
        .get(`/groups/${groupId}/users/${userId}/membership-data`)
        .then((response: any) => {
          if (response) {
            resolve(response);
          }
        }).catch((error: any) => {
          reject(error);
          new Error("Something went wrong!")
        });
    });
  };

  public postIntroductions: (groupId: number, payload: any) => Promise<void> = (groupId, payload) => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
        .post(`/groups/${groupId}/community-introduction`, payload)
        .then((response: any) => {
          resolve(response);
        }).catch((error: any) => {
          reject(error);
          new Error("Something went wrong!")
        });
    });
  };

  public getIntroductions: (groupId: number) => Promise<void> = (groupId,) => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
        .get(`/groups/${groupId}/community-introduction`)
        .then((response: any) => {
          resolve(response);
        }).catch((error: any) => {
          reject(error);
          new Error("Something went wrong!")
        });
    });
  };

  public postWelcomeScreen: (groupId: number, payload: any) => Promise<void> = (groupId, payload) => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
        .post(`/groups/${groupId}/community-screen`, payload)
        .then((response: any) => {
          resolve(response);
        }).catch((error: any) => {
          reject(error);
          new Error("Something went wrong!")
        });
    });
  };

  public getWelcomeScreen: (groupId: number) => Promise<void> = (groupId) => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
        .get(`/groups/${groupId}/community-screen`)
        .then((response: any) => {
          resolve(response);
        }).catch((error: any) => {
          reject(error);
          new Error("Something went wrong!")
        });
    });
  };

  public postCommunityFields: (groupId: number, payload: any) => Promise<void> = (groupId, payload) => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
        .post(`/groups/${groupId}/community-fields`, payload)
        .then((response: any) => {
          resolve(response);
        }).catch((error: any) => {
          reject(error);
          new Error("Something went wrong!")
        });
    });
  };

  public getCommunityFields: (groupId: number, query?: string) => Promise<void> = (groupId, query) => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
        .get(`/groups/${groupId}/community-fields${query ? `?category=${query}` : ''}`)
        .then((response: any) => {
          resolve(response);
        }).catch((error: any) => {
          reject(error);
          new Error("Something went wrong!")
        });
    });
  };

  public updateCommunityFields: (groupId: number, fieldId: any, payload: any) => Promise<void> = (groupId, fieldId, payload) => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
        .put(`/groups/${groupId}/community-fields/${fieldId}`, payload)
        .then((response: any) => {
          resolve(response);
        }).catch((error: any) => {
          reject(error);
          new Error("Something went wrong!")
        });
    });
  };

  public deleteCommunityField: (groupId: number, fieldId: any) => Promise<void> = (groupId, fieldId) => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
        .delete(`/groups/${groupId}/community-fields/${fieldId}`)
        .then((response: any) => {
          resolve(response);
        }).catch((error: any) => {
          reject(error);
          new Error("Something went wrong!")
        });
    });
  };

  public updateOnboardingSteps: (groupId: number, membershipId: any, payload: any) => Promise<void> = (
    groupId, membershipId, payload) => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
        .put(`/groups/${groupId}/memberships/${membershipId}`, payload)
        .then((response: any) => {
          resolve(response);
        }).catch((error: any) => {
          reject(error);
          new Error("Something went wrong!")
        });
    });
  };

  public getGroupMembersForEventHosts: (
    groupId: number, query: string
  ) => Promise<void> = (groupId, query = "",) => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
        .get(`/groups/${groupId}/memberships${query}`)
        .then((response: any) => resolve(response))
        .catch((error: any) => {
          reject(error)
          new Error("Something went wrong!")
        });
    });
  };

  public addFacebookPixelIntergration: (
    groupId: any,
    thirdPartyId: any,
    payload: any
  ) => Promise<void> = (groupId, thirdPartyId, payload) => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
        .post(`/groups/${groupId}/third-party/${thirdPartyId}/direct-config`,
          { "configuration": payload }
        )
        .then((response: any) => resolve(response))
        .catch((error: any) => {
          reject(error)
          new Error("Something went wrong!")
        });
    });
  };

  public exportPlanMembers: (
    groupId: number,
    planId: number,
  ) => Promise<any> = (groupId, planId) => {
    return new Promise<any>((resolve, reject) => {
      this.dbInstance
        .post(`groups/${groupId}/plans/${planId}/members/export`)
        .then((response: any) => resolve(response))
        .catch((error: any) => {
          reject(error)
          new Error("Something went wrong!")
        });
    });
  };

  public getAdminCommunityPlans: (
    groupId: number,
    query?: string,
  ) => Promise<void> = (groupId, query = "", filter = "") => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
        .get(`/groups/${groupId}/admin-plans?search=${query}`)
        .then((response: any) => { resolve(response) })
        .catch((error: any) => {
          reject(error)
          new Error("Something went wrong!")
        })
    });
  };

  public loadMoreAdminCommunityPlans: (
    url: string,
  ) => Promise<void> = (url) => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
        .get(url)
        .then((response: any) => { resolve(response) })
        .catch((error: any) => {
          reject(error)
          new Error("Something went wrong!")
        })
    });
  };

  // for universal search
  public getCommunityContent: (groupId: number, searchQuery: string) => Promise<void> = (groupId, searchQuery) => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
        .get(`/groups/${groupId}/universal-search?search=${searchQuery}`)
        .then((response: any) => {
          resolve(response);
        })
        .catch((error: any) => {
          reject(error);
          new Error("Something went wrong!")
        });
    });
  };

  public loadMoreCommunityContent: (url: string) => Promise<void> = (url) => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
        .get(url)
        .then((response: any) => {
          resolve(response);
        })
        .catch((error: any) => {
          reject(error);
          new Error("Something went wrong!")
        });
    });
  };

  public sentFBPixelTestEvents: (
    groupId: any,
    payload: any
  ) => Promise<void> = (groupId, payload) => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
        .post(`/groups/${groupId}/test-facebook-events`,
          payload
        )
        .then((response: any) => resolve(response))
        .catch((error: any) => {
          reject(error)
          new Error("Something went wrong!")
        });
    });
  };

  public shopifyCustomInstallation: (
    payload: any
  ) => Promise<void> = (
    payload
  ) => {
      return new Promise<void>((resolve, reject) => {
        this.dbInstance
          .post(`/integrations/shopify/custom-installation`, payload)
          .then((response: any) => {
            resolve(response.data);
          }).catch((error: any) => {
            reject(error);
            new Error("Something went wrong!")
          });
      });
    };

  public addExternalLinks: (
    groupId: any,
    payload: any
  ) => Promise<void> = (groupId, payload) => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
        .post(`/groups/${groupId}/eternal-links`,
          payload
        )
        .then((response: any) => resolve(response))
        .catch((error: any) => {
          reject(error)
          new Error(error)
        });
    });
  };

  public deleteExternalLink: (
    groupId: any,
    linkID: any
  ) => Promise<void> = (groupId, linkID) => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
        .delete(`/groups/${groupId}/eternal-links/${linkID}`)
        .then((response: any) => resolve(response))
        .catch((error: any) => {
          reject(error)
          new Error(error)
        });
    });
  };

  public editExternalLink: (
    groupId: any,
    linkID: any,
    payload: any
  ) => Promise<void> = (groupId, linkID, payload) => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
        .put(`/groups/${groupId}/eternal-links/${linkID}`, payload)
        .then((response: any) => resolve(response))
        .catch((error: any) => {
          reject(error)
          new Error(error)
        });
    });
  };

}
